import * as React from 'react';
import { FC } from 'react';

interface PageContainerProps {}

const Layout: FC<PageContainerProps> = ({ children }) => {
  return <div style={{ height: '100%' }}>{children}</div>;
};

export default Layout;
