// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-action-tsx": () => import("./../../../src/pages/action.tsx" /* webpackChunkName: "component---src-pages-action-tsx" */),
  "component---src-pages-email-verification-error-tsx": () => import("./../../../src/pages/emailVerificationError.tsx" /* webpackChunkName: "component---src-pages-email-verification-error-tsx" */),
  "component---src-pages-email-verified-tsx": () => import("./../../../src/pages/emailVerified.tsx" /* webpackChunkName: "component---src-pages-email-verified-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-reset-completed-tsx": () => import("./../../../src/pages/resetCompleted.tsx" /* webpackChunkName: "component---src-pages-reset-completed-tsx" */),
  "component---src-pages-reset-password-error-tsx": () => import("./../../../src/pages/resetPasswordError.tsx" /* webpackChunkName: "component---src-pages-reset-password-error-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/resetPassword.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */)
}

